import * as React from "react"
import { useContext } from "react"
import styled from "styled-components"
import { colors, mq } from "../utils/styles"
import { Link } from "gatsby"
import useWindowSize from "../hooks/useWindowSize"
import I18nContext from "../utils/i18nContext"
import { makeLocalePath } from "../utils/localization"

const Wrapper = styled.div`
  position: fixed;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: ${props =>
    props.windowHeight ? props.windowHeight + "px" : "100dvh"};
  background-color: ${colors.background};
  opacity: ${props => (props.venuesLoaded ? 0 : 1)};
  pointer-events: ${props => (props.venuesLoaded ? "none" : "inherit")};
  transition: all 600ms;
  padding: 3rem;
`

const Title = styled.h1`
  margin: 0;
  font-size: 3rem;
  text-align: center;

  ${mq.large} {
    font-size: 5vw;
  }
`
const Content = styled.div`
  margin-bottom: auto;
  text-align: center;
`
const Text = styled.p`
  font-size: 1.5rem;
  text-align: center;

  ${mq.large} {
    font-size: 2vw;
  }
`
const LogoLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  display: block;
  margin-bottom: auto;
`
const LogoText = styled.h1`
  margin: 0;
  font-size: 3rem;
`
const LogoSmall = styled.span`
  font-size: 0.6em;
  font-weight: normal;
  color: ${colors.main};
`
const Button = styled(props => <Link {...props} />)`
  margin-bottom: 2em;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 2px solid ${colors.main};
  color: ${colors.main};

  &:hover {
    cursor: pointer;
    background-color: ${colors.main};
    color: #fff;
  }
`
export default function Index(props) {
  const locale = props.pageContext.locale
  const windowSize = useWindowSize()
  const { translate } = useContext(I18nContext)
  return (
    <Wrapper windowHeight={windowSize.height}>
      <LogoLink to="/">
        <LogoText>
          Music <LogoSmall>in</LogoSmall> Paris
        </LogoText>
      </LogoLink>
      <Content>
        <Title>{translate(`404 | Introuvable`)}</Title>
        <Text>{translate(`Désolé, cette page n'existe pas.`)}</Text>
        <Button to={makeLocalePath("", "/", locale)}>
          {translate(`Retour à l'accueil`)}
        </Button>
      </Content>
    </Wrapper>
  )
}
